.isprava-form-wrapper {
  @media print {
    display: none;
  }

  padding: 20px 0;
  text-align: center;

  textarea {
    margin: 20px 0;
    padding: 10px;

    width: 80%;
    max-width: 80%;
    height: 300px;
  }

  button {
    padding: 10px 20px;
  }

  hr {
    margin-top: 30px;
    border-top: 1px solid color(text);
    border-bottom: 0;
  }
}

div.isprave {
  .isprava {
    &:nth-of-type(n+2) {
      page-break-before: always;
    }

    table {
      width: 100%;
    }
  }

  hr {
    margin-top: 50px;
    border-top: 1px solid color(text);
    border-bottom: 0;
  }
}
