.header {
  @media print {
    display: none;
  }

  &-group {
    background: color(background-header);
  }

  display: flex;

  padding: rem(20) 0;

  text-align: center;

  align-items: center;

  @include breakpoint($tablet) {
    text-align: inherit;
  }

  &__content {
    display: flex;

    margin-left: auto;

    align-items: center;
  }

  &__languages {
    ul {
      display: flex;

      li {
        display: flex;

        &:not(:last-child) {
          &:after {
            content: ' | ';

            display: inline-flex;

            margin: 0 rem(10);
          }
        }

        a {
          text-decoration: underline;
        }
      }
    }
  }

  &__user {
    display: flex;

    margin-left: rem(20);

    &__name {
      &:after {
        content: ' | ';

        display: inline-flex;

        margin: 0 rem(10);
      }
    }

    &__logout {
      a {
        text-decoration: underline;
      }
    }
  }
}

// .header__bar .main__title,
// .header__bar p {
//   padding: 0;
//   margin: rem(10) 0;
// }
