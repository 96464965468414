:root {

  /* prevents mobile browsers from sometimes scaling text */
  text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  display: flex;
  min-height: 100vh;

  flex-direction: column;

  font-family: $font-main;
  line-height: 1.5;
  color: color(text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  background-color: color(background);

  scroll-behavior: smooth;
}

main {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.container {
  display: flex;
  width: 100%;
  max-width: calc(1575px - 25px); // 1500px

  margin: 0 auto;
  padding: 0 rem(25);

  flex-direction: column;
}

.hide {
  display: none;
}

section {
  display: flex;

  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul,
ol {
  padding: 0;
  margin: 0;

  list-style: none;
}

p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;

  transition: color 0.2s linear;

  // &:hover {
  //   color:
  // }
}
